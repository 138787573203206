import { Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.demo';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(PLATFORM_ID) 
    private platformId: Object,
    private http: HttpClient
  ) {}

  public login(body: any): Observable<any> {
    return this.http.post<any>(environment.ADMIN_LOGIN, body);
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !!localStorage.getItem('token'); // Devuelve true si hay un token
    }
    return false;
  }

  public logout(): void {
    window.localStorage.clear();
  }
}